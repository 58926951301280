const normalEngravings = [
  'Disrespect',
  'Spirit Absorption',
  'Ether Enchancement',
  'Stabilized Status',
  'Grudge',
  'Super Charge',
  'Strong Will',
  'Drops of Ether',
  'Crisis Evasion',
  'Keen Blunt Weapon',
  'Vital Point Strike',
  'Increased Max MP',
  'MP Regen',
  'Master of Escape',
  'Fortitude',
  'Crushing Fist',
  'Shield Piercing',
  "Masters's Tenacity",
  'Divine Protection',
  'Heavy Armor',
  'Explosive Expert',
  'Enchanced Shield',
  'Necromancy',
  'Preemptive Strike',
  'Broken Bone',
  'Lighting Fury',
  'Cursed Doll',
  'Contender',
  'Ambush Master',
  'Magick Stream',
  'Barricade',
  'Raid Captain',
  'Awakening',
  'Master Brawler',
  'Mass Increase',
  'Propulsion',
  'Hit Master',
  'Adrenaline',
  'All-Out Attack',
  'Expert',
  'Emergency Rescue',
  'Precision Dagger',
  'Sight Focus'
];

const classEngravings = [
  'Mayhem',
  "Berserker's Technique",
  'Enhanced Weapon',
  'Pistoleer',
  'Esoteric Skill Enhancement',
  'First Intention',
  'Ultimate Skill: Taijutsu',
  'Shock Training',
  'Barrage',
  'Firepower Enhancement',
  'True Courage',
  'Desperate Salvation',
  'Reflux',
  'Igniter',
  'Esoteric Flurry',
  'Deathblow',
  'Time To Hunt',
  'Peacemaker',
  'Blessed Aura',
  'Judgement',
  'Demonic Impulse',
  'Perfect Suppression',
  'Surge',
  'Remaining Energy',
  'Death Strike',
  'Loyal Companion',
  'Energy Overflow',
  'Robust Spirit',
  'Lone Knight',
  'Combat Readiness',
  'Control',
  'Pinnacle',
  'Gravity Training',
  'Rage Hammer',
  'Emperor\'s Edict',
  'Empress\'s Grace',
  'Order of the Emperor',
  'Arthetinean Skill',
  'Evolutionary Legacy',
  'Hunger',
  'Lunar Voice',
  'Master Summoner',
  'Communication Overflow',
  'Predator',
  'Punisher',
  'Full Bloom',
  'Recurrence',
  'Drizzle',
  'Wind Fury'
];

const negativeEngravings = [
  'Attack Power Decrease',
  'Attack Speed Decrease',
  'Defense Decrease',
  'Speed Decrease'
];

module.exports = {
  normalEngravings,
  classEngravings,
  negativeEngravings
}